@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

body {
	margin: 0;
	padding: 0;
	font-family: 'Rock Salt', cursive; /* Grungy, distressed font for the entire site */
	background-color: #121212; /* Dark background for a grungy feel */
	color: #e0e0e0; /* Light text color */
}

a {
	color: #ff6600; /* Grungy orange for links */
	text-decoration: none;
}

a:hover {
	color: #ff3333; /* Grungy red on hover */
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.header,
.footer {
	background-color: #333; /* Darker background for header and footer */
	color: #e0e0e0; /* Light text color */
	text-align: center;
}

.nav-bar {
	background-color: #333; /* Darker background for nav bar */
	padding: 10px;
	border-bottom: 3px solid #ff6600; /* Grungy orange border */
	font-family: 'Rock Salt', cursive; /* Grungy font for nav links */
	position: sticky; /* Ensure positioning for mobile */
	z-index: 1;
	top: 0;
}

.nav-bar ul {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	list-style: none;
}

.nav-bar li {
	margin: 0 15px;
}

.nav-bar a {
	color: #e0e0e0; /* Light text color for nav links */
	font-weight: bold;
	text-transform: uppercase; /* Add uppercase for a bolder 90s feel */
	font-size: 1.2em;
}

.nav-bar a:hover {
	color: #ff3333; /* Grungy red on hover */
}

.nav-bar .hamburger {
	display: none; /* Hide the hamburger menu by default */
	cursor: pointer;
	font-size: 1.5em;
	color: #ff6600; /* Grungy orange for hamburger icon */
	position: sticky;
	top: 0;
}

@media (max-width: 768px) {
	.nav-bar {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.nav-bar ul {
		display: none; /* Hide menu by default on mobile */
		flex-direction: column;
		margin: 0;
		padding: 0;
	}

	.nav-bar ul.active {
		display: flex; /* Show menu when active */
	}

	.nav-bar .hamburger {
		display: block;
	}

	.nav-bar li {
		margin: 10px 0;
	}
}

/* Footer Styles */
.footer {
	position: fixed; /* Ensures the footer stays at the bottom of the viewport */
	bottom: 0;
	width: 100%;
	background-color: #1a1a1a;
	color: white;
	text-align: center;
	padding: 2px 8px; /* Further reduced padding for an even smaller footer */
	font-size: 0.7rem; /* Smaller font size for a more compact look */
	line-height: 1.2; /* Reduce line height to keep it tight */
	box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3); /* Lighter shadow for a subtler effect */
	z-index: 999; /* Ensures it stays on top of other content */
}

.body-content {
	padding-bottom: 60px; /* Creates space to prevent the footer from overlapping content */
}
