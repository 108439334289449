/* Footer Styling */
.footer {
	background-color: #1a1a1a; /* Dark background color for the footer */
	padding: 20px 0;
	color: #f0f0f0; /* Light text color for contrast */
	text-align: center;
}

.footer-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
}

.footer-social-icons {
	margin-bottom: 10px;
}

.social-icon {
	text-decoration: none; /* Remove underline from links */
	margin: 0 15px; /* Spacing between icons */
	color: #f0f0f0; /* Icon color */
	font-size: 1.8em; /* Icon size */
	transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

.social-icon:hover {
	color: #ff6600; /* Change color on hover */
	transform: scale(1.2); /* Slightly increase size on hover */
}

.footer-text p {
	margin: 0;
	font-size: 1em;
}

.footer-text {
	margin-top: 15px;
}

@media (max-width: 768px) {
	.social-icon {
		margin: 0 10px;
		font-size: 1.5em;
	}

	.footer-text p {
		font-size: 0.9em;
	}
}
