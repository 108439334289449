@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

.biography-container {
	padding: 20px;
	background-color: #1a1a1a;
	font-family: 'Lora', serif;
}

.biography-title {
	font-size: 2.8em;
	color: #ff9900;
	margin-bottom: 20px;
	text-align: center;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.biography-content {
	font-size: 1.3em;
	color: #f0f0f0;
	line-height: 1.8;
	opacity: 0;
	animation: fadeIn 1s forwards;
	margin-bottom: 75px;
}

.highlight {
	color: #ff6666;
	font-weight: bold;
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

.biography-photo {
	width: 100%; /* Make the photo as wide as the container */
	max-width: 400px; /* Set a maximum width to prevent it from becoming too large */
	height: auto; /* Maintain aspect ratio */
	border-radius: 10px; /* Rounded corners */
	margin: 0 auto 20px; /* Center the image and add some space below */
	display: block; /* Center the image */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

/* Additional styles for smaller screens */
@media (max-width: 768px) {
	.biography-photo {
		max-width: 100%; /* Allow the photo to take up the full width on smaller screens */
		margin-bottom: 15px; /* Slightly reduce the bottom margin */
	}
}
