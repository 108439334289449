@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.show-dates-container {
	padding: 20px;
	background-color: #121212; /* Darker background for consistency */
}

.show-dates-title {
	font-size: 2.5em;
	color: #ff6600; /* Grungy orange for title */
	margin-bottom: 20px;
	text-align: center;
	text-shadow: 2px 2px 0 #ff3333; /* Rough, distressed glow effect */
}

.show-dates-list {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 75px;
}

.show-date-item {
	background-color: #1e1e1e; /* Slightly lighter background for items */
	border: 1px solid #333;
	border-radius: 8px;
	padding: 15px;
	margin: 10px 0;
	opacity: 0;
	transform: translateY(20px);
	animation: fadeInUp 0.6s forwards;
}

.show-date-item:nth-child(odd) {
	animation-delay: 0.2s;
}

.show-date-item:nth-child(even) {
	animation-delay: 0.4s;
}

@keyframes fadeInUp {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.date {
	font-size: 1.5em;
	color: #ff6600; /* Grungy orange for date */
	font-weight: bold;
}

.details {
	color: #e0e0e0; /* Light text color */
}

.venue,
.location,
.time {
	margin: 5px 0;
}
